import React, { createContext, useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa';

const AppContext = createContext();

const LearnItemDictionarytemplate = {
  item1: {
    name: 'Genome Aligner',
    file: 'GenomeAlignment.jsx',
  },
  item2: {
    name: 'Genotype Imputation',
    file: 'GenotypeImputation.jsx',
  },
  item3: {
    name: 'Central Limit Theorem',
    file: 'CentralLimitTheorem.jsx',
  },
  item4: {
    name: 'Linear Algebra',
    file: 'LinearAlgebra.jsx',
  },
};

const AppProvider = ({ children }) => {
  const [activeButton, setActiveButton] = useState(null);
  const [LearnItemDictionary, setlearnItemDictionary] = useState(LearnItemDictionarytemplate);

  return (
    <AppContext.Provider value={{ activeButton, setActiveButton, LearnItemDictionary, setlearnItemDictionary }}>
      {children}
    </AppContext.Provider>
  );
};

const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: #f8f8bb;
  position: relative; /* Ensure the container has position relative */
`;

const Sidebar = styled.div`
  flex: 0 0 auto;
  width: ${({ collapsed }) => (collapsed ? '50px' : '250px')};
  background-color: #f0f0f0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 20px;
  transition: width 0.3s ease;
  overflow-x: hidden;
  z-index: 1; /* Ensure the sidebar has a lower z-index */
`;

const ToggleButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100%;
`;

const MenuList = styled.ul`
  padding: 0;
  display: ${({ collapsed }) => (collapsed ? 'none' : 'block')};
`;

const MenuItem = styled.li`
  list-style-type: none;
`;

const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 1px 10px;
  margin-bottom: 8px;
  cursor: pointer;
  color: #333;
  font-size: 16px;
  text-align: left;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  width: 100%;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ddd;
  }

  ${({ active }) =>
    active &&
    `
    background-color: #bbb;
  `}
`;

const ContextComponent = () => {
  const { activeButton, LearnItemDictionary } = useContext(AppContext);
  const [selectedComponent, setSelectedComponent] = useState(null);

  useEffect(() => {
    const importComponent = async () => {
      try {
        const fileName = LearnItemDictionary[activeButton]["file"];
        const module = await import(`../../learn/${fileName}`);
        const NewComponent = module.default;
        setSelectedComponent(<NewComponent />);
      } catch (error) {
        console.log('Error importing component:', error);
      }
    };

    importComponent();
  }, [activeButton, LearnItemDictionary]);

  return (
    <div style={{ position: 'relative', overflowY: 'auto', overflowX: 'visible', flexGrow: 1 }}>
      {selectedComponent && selectedComponent}
    </div>
  );
};

const SideBarMenuComponent = ({ label, handleButtonClick, isThisActive }) => {
  return (
    <MenuItem>
      <MenuButton
        onClick={() => handleButtonClick()}
        active={isThisActive()}
      >
        {label}
      </MenuButton>
    </MenuItem>
  );
};

const SideBarComponent = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { activeButton, setActiveButton, LearnItemDictionary } = useContext(AppContext);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const handleButtonClick = (menuItem) => {
    setActiveButton(menuItem);
  };

  const isThisActive = (menuItem) => {
    return menuItem === activeButton;
  };

  return (
    <Sidebar collapsed={collapsed}>
      <ToggleButton onClick={toggleSidebar}>
        {collapsed ? <FaBars /> : <FaTimes />}
      </ToggleButton>
      <MenuList collapsed={collapsed}>
        {Object.entries(LearnItemDictionary).map(([key, value]) => (
          <SideBarMenuComponent
            key={key}
            label={value.name}
            handleButtonClick={() => handleButtonClick(key)}
            isThisActive={() => isThisActive(key)}
          />
        ))}
      </MenuList>
    </Sidebar>
  );
};

const containerStyle = {
  padding: '20px',
  backgroundColor: '#f0f0f0',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
  margin: '20px',
  overflowY: 'auto',
  overflowX: 'visible',
  width: 'calc(100% - 40px)',
  height: '100%',
  boxSizing: 'border-box',
};

const ContainerComponent = () => {
  return (
    <AppProvider>
      <Container>
        <SideBarComponent />
        <div style={containerStyle}>
          <ContextComponent />
        </div>
      </Container>
    </AppProvider>
  );
};

export default ContainerComponent;
