import React from 'react';
import { Nav, Navbar, Form, FormControl } from 'react-bootstrap';
import styled from 'styled-components';
import logoImg from '../../images/logo-transparent.png'; // Import the PNG image file

const Styles = styled.div`
  .navbar {
    background-color: rgb(6, 119, 218);
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }

  a, .navbar-nav, .navbar-light .nav-link {
    color: #aaeb48 !important;
    &:hover { color: white; }
  }

  .navbar-brand img {
    height: 60px;
    width: auto;
  }

  .form-center {
    width: 60%; /* Adjust the width as needed */
    margin-left: 30px;
    right: 0;
  }

  .nav-item a {
    font-size: 18px; /* Adjust the font size as needed */
    padding: 8px 16px; /* Adjust the padding as needed */
    border: 2px solid transparent; /* Add border for button-like appearance */
    border-radius: 5px; /* Add border radius for button-like appearance */
    text-decoration: none;
    transition: all 0.3s ease; /* Add transition for smooth hover effect */

  }

  .nav-item a:hover {
    background-color: #2290ef; /* Change background color on hover */
    color: #2f12b1; /* Change text color on hover */
    border-color: #f49fff; /* Change border color on hover */
  }

`;

export const MyHeader = () => (
  <Styles>
    <Navbar expand="lg" className="navbar-light">
      <Navbar.Brand href="/">
        <img src={logoImg} alt="Logo" />
      </Navbar.Brand>
      <Form className="form-center">
        <FormControl type="text" placeholder="Search" className="" />
      </Form>
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Item><Nav.Link href="/" className="nav-link">Home</Nav.Link></Nav.Item> 
          <Nav.Item><Nav.Link href="/about" className="nav-link">About</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link href="/learn" className="nav-link">Learn</Nav.Link></Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </Styles>
);

export default MyHeader;
