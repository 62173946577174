import React from 'react';
import styled from 'styled-components';

// Styled components
const Header = styled.header`
  background: linear-gradient(rgb(6, 119, 218), rgba(229, 23, 99, 0.5)), url('your-image.jpg');
  padding: 20px;
  text-align: center;
  color: #62e978;

`;

const Container = styled.div`
  max-width: 1200px;
  margin: 20px auto;
  padding: 0 20px;
`;

const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
`;


const TextContent = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const Description = styled.p`
  padding: 10px ;
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  background-color: #c8f6e7;

`;

const About = () => {
  return (
    <>
      <Header>
        <h1>About PixelTeach</h1>
      </Header>
      <Container>
        <MainContent>
          <TextContent>
            <Description>
              <p>We are a small group of friends who share a passion for teaching and a vision for transforming modern education. We've taken up this project as a side endeavor, dedicating our weekends and evenings to it. Our goal is to create interactive learning tools that engage students through activities, games, and discussions. We believe, unlike traditional methods, our approach might make learning more engaging and hands-on and help students understand concepts better, think critically, and develop a lifelong love for learning.</p>
            </Description>
          </TextContent>
        </MainContent>
      </Container>
    </>
  );
};

export {About};

