
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import MyHeader from "./layout/main/Header.jsx";
import { About } from './layout/About.jsx';
import { Home } from './layout/Home.jsx';
import { NoMatch } from './layout/NoMatch.jsx';
import { Learn } from './layout/Learn.jsx';


function App() {


      return (
        <>
          <MyHeader />
          <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/learn" element={<Learn/>} />
            <Route element={<NoMatch/>} />
          </Routes>
        </>
        );
}

export default App;
