import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logoImg from '../images/logo-transparent.png'; // Import the PNG image file

// Styled components

const Container = styled.div`
  max-width: 1200px;
  margin: 20px auto;
  padding: 0 20px;
`;

const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  /* background-color: #5164e2; */
  background: linear-gradient(rgba(17, 41, 198, 0.5), rgba(229, 23, 99, 0.5)), url('your-image.jpg');

`;

const TextContent = styled.div`
  flex: 1;
  margin-left: 20px;
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
  margin-top: 20px;
  color: darkviolet;
`;

const Description = styled.p`
  font-size: 18px;
  line-height: 1.6;
  color: #666;
`;


const StyledLink = styled(Link)`
  background-color: #266ef4;
  color: #bcfe2d;
  padding: 12px 20px;
  font-size: 20px;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #1903a3;
    color: #b1ff08;
    text-decoration: none; /* Remove underline on hover */
  }

  &:focus {
    outline: none; /* Remove outline on focus */
  }
`;


const Home = () => {
  return (
    <>
      {/* <Header>
        <h1>Welcome to PixelTeach</h1>
      </Header> */}
      <Container>
        <MainContent>
          <Image src={logoImg} alt="PixelTeach Logo" />
          <TextContent>
            <Title>Interactive Learning Made Simple</Title>
            <Description>
              PixelTeach offers an immersive educational experience through interactive tools and engaging content. Explore topics at your own pace and interact with fellow learners in a dynamic online environment.
            </Description>
            <StyledLink to="/learn">Get Started</StyledLink>
          </TextContent>
        </MainContent>
      </Container>
    </>
  );
};

export {Home};


// const GridWrapper = styled.div`
//   display: grid;
//   grid-gap: 10px;
//   margin-top: 1em;
//   margin-left: 6em;
//   margin-right: 6em;
//   grid-template-columns: repeat(12, 1fr);
//   grid-auto-rows: minmax(25px, auto);
// `;


// export const Home = (props) => (
//     <GridWrapper>
//       <p>This is a paragraph and I am writing on the home page</p>
//       <p>This is another paragraph, hi hey hello whatsup yo</p>
//     </GridWrapper>
//   )

  