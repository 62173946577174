import React from 'react';
import Container from './main/Container.jsx';


 function Learn() {

    console.log("mounted")

   return (<div>
      <Container />
    </div>)
 }

  export {Learn};
